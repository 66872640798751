<template>
  <div class="content-wrapper">
    <bo-page-title>
      <template slot="additionalCta">
          <b-button @click="download('', {exptype: 'xlsx', tab: 'prospect-from-portal'})" class="bg-green-400 btn-labeled btn-labeled-left">
            <b><i class="icon-file-spreadsheet"></i></b> Download Excel
          </b-button>
      </template>
    </bo-page-title>
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col md="4">
                  <b-form-group>
                    <v-select placeholder="Semua Type Debitur" @input="doFill" v-model="filter.typeDebitur" :options="Config.mr.JenisDebitur" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.status" :options="Config.mr.StatusOptions" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum ada data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          <template #cell(contact)="data">
              <div class="mb-2">
                <div class="d-flex align-items-center">
                    <div class="mr-2">
                        <span class="icon-user text-info"></span>
                    </div>
                    <div>
                        <a href="javascript:;" class="text-default  letter-icon-title">{{data.item.dfp_pic_name||"-"}}</a>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div class="mr-2">
                        <span class="icon-phone2 text-danger"></span>
                    </div>
                    <div>
                        <a href="javascript:;" class="text-default letter-icon-title">{{data.item.dfp_pic_contact||"-"}}</a>
                    </div>
                </div>
              </div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            <template #cell(dfp_reg_date)="data">
              {{data.value|moment("DD MMMM YYYY, HH:mm")}}
            </template>
            <template #cell(dfp_deb_type)="data">
              {{data.value=='CORPORATE' ? 'Perusahaan' : 'Individu'}}
            </template>
            <template #cell(mbu_desc_id)="data">
              {{data.value||"-"}}
            </template>
            <template
              #cell(dfp_status) = "data"
            >
              <span v-if="data.value == 'N'" class="badge badge-warning">Need Assignment</span>
              <span v-else-if="data.value == 'R'" class="badge badge-danger">Reject</span>
              <span v-else>-</span>
            </template>
            <template
              #cell(dfp_est) = "data"
            >
              <strong>Rp. {{data.value ? parseInt(data.value||'0').toLocaleString('id-ID') : "-"}}</strong>
            </template>
            <template
              #cell(action) = "data"
            >
              <div class="list-icons">
                  <a href="javascript:;" class="list-icons-item text-info-600"
                  v-if="moduleRole('assign') && data.item.dfp_status == 'N'"
                  @click="openModalId(data.item['dfp_id'])"
                  data-toggle="tooltip" data-placement="top" title="Assign ID"><i
                    class="icon-file-check"></i></a>
              
                  <a href="javascript:;" class="list-icons-item text-danger-600"
                  v-if="moduleRole('reject') && data.item.dfp_status == 'N'"
                  @click="doReject(data.item['dfp_id'])"
                  data-toggle="tooltip" data-placement="top" title="Reject"><i
                    class="icon-cross"></i></a>

                  <div class="" v-if="data.item.dfp_status != 'N'">
                      -
                  </div>
              </div>
              <span v-if="!(moduleRole('reject') || moduleRole('assign'))">-</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>10">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      
      <b-modal 
        v-model="modalOpen"
        :title="'Assign ID Officer'"
        size="md"
        hide-footer
        body-class="p-0"
      >
          
          <validation-observer
              v-slot="{ handleSubmit }" ref="VForm"
          >
              <b-form @submit.prevent="handleSubmit(doSubmit())">
                  <b-card-body>
                      <div class="table-responsive">
                          <table class="table table-bordered">
                              <tbody id="detail_bo">
                                  <tr>
                                      <td>
                                          <div class="result_tab">
                                              <h4>Nama Debitur</h4>
                                              <p>{{row.dfp_nama_debitur}}</p>
                                          </div>
                                      </td>
                                      <td>
                                          <div class="result_tab">
                                              <h4>Jenis Debitur</h4>
                                              <p>{{row.dfp_deb_type == 'CORPORATE' ? 'Perusahaan' : 'Individu'}}</p>
                                          </div>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <div class="result_tab">
                                              <h4>Bidang Usaha</h4>
                                              <p>{{row.mbu_desc_id}}</p>
                                          </div>
                                      </td>
                                      <td>
                                          <div class="result_tab">
                                              <h4>Estimasi Pembiayaan</h4>
                                              <p>Rp. {{row.dfp_est||"-"}}</p>
                                          </div>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="form-group mt-3">
                          <label for="">Pilih ID Officer <small class="txt_mandatory">*</small></label>
                          <v-select @search="searchIDOfficer($event)" @open="clearIDOfficer" v-model="row.dfp_id_officer" placeholder="Pilih ID Officer" :options="mrIDOfficer" label="text" :clearable="true" :reduce="v=>v.value">
                              <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching && search.length > 2">
                                    Tidak ada hasil untuk <em>{{ search }}</em
                                      >.
                                  </template>
                                  <span v-else style="opacity: 0.5">Input 3 karakter untuk mencari ID Officer.</span>
                              </template>
                          </v-select>
                          <VValidate 
                              name="ID Officer" 
                              v-model="row.dfp_id_officer" 
                              :rules="{required:1}"
                          />
                      </div>
                  </b-card-body>
                  <b-card-footer>
                      <b-row>
                          <b-col lg="12" class="text-right">
                              <button type="button" @click="modalOpen = false" class="btn btn-link" data-dismiss="modal">Cancel</button>
                              <button type="submit" class="btn bg-primary">Submit</button>
                          </b-col>
                      </b-row>
                  </b-card-footer>
              </b-form>
          </validation-observer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
// import $ from 'jquery'
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  data() {
    return {
      idKey:'dfp_id',
      statusKey:'dfp_status',
      fields: [
        {
          key: 'number',
          label: '#',
        },
        {
          key: 'dfp_reg_date',
          label: 'Tanggal',
        },
        {
          key: 'dfp_deb_type',
          label: 'Jenis Debitur',
        },
        {
          key: 'dfp_nama_debitur',
          label: 'Nama Debitur',
        },
        {
          key: 'mbu_desc_id',
          label: 'Bidang Usaha',
        },
        {
          key: 'dfp_est',
          label: 'Est. Pembiayaan (IDR)',
        },
        {
          key: 'contact',
          label: 'Kontak PIC',
        },
        {
          key: 'dfp_status',
          label: 'Status',
        },
        {
          key: 'action',
        },
      ],
      modalOpen : false,
      mrIDOfficer : []
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    clearIDOfficer(){
        this.mrIDOfficer = []
        this.row.dfp_id_officer = null
    },
    searchIDOfficer(e){
        if(e.length > 2){
            Gen.apiRest('/do/'+this.modulePage, {data: {type:'select-id-officer', search: e}}, 'POST').then(res=>{
                this.mrIDOfficer = res.data.data
            })
        }
    },
    openModalId(id){
        this.loadingOverlay=true
        Gen.apiRest(
            "/get/"+this.modulePage+"/"+id,
            {}
        ).then(res=>{
            _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
            })
            this.modalOpen = true
            this.loadingOverlay=false
        })
    },
    doReject(id){
        this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Akan Menolak Permintaan Debitur ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        }).then(result => {
            if (result.value) {
                Gen.apiRest('/do/'+this.modulePage, {data: {type:'reject', id: id}}, 'POST').then(res=>{
                    res.data.statusType = 200            
                    this.doSetAlertForm(res.data)
                    this.apiGet()
                })
            }
        })   
    },
    doSubmit(){
        this.$refs['VForm'].validate().then(success => {
            if(success){
                this.loadingOverlay = true
                let data = this.row
                data.type = 'assignment'
                Gen.apiRest('/do/'+this.modulePage, {data: data}, 'POST').then(res=>{
                    res.data.statusType = 200                    
                    this.doSetAlertForm(res.data)
                    this.apiGet()
                    this.loadingOverlay = false
                    this.modalOpen = false
                }).catch(err=>{
                    if(err){
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title ? err.response?.data?.title : err.response?.data?.message
                        err.message = " "
                        err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                    this.loadingOverlay = false
                    this.modalOpen = false
                })
            }
        })
    },
    doFill(){
      this.doFilter()
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>